<template>
  <div class="main-container">
    <div v-for="(item, index) in items" :key="index">
      <FAQContentCell
        :title="item"
        :noStr="showNoStr(index)"
        @onOpen="onOpen(index)"
        :isOpen="showOpen(index)"
        :index = "index"
        :isMmol = "isMmol"
      ></FAQContentCell>
      
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

import FAQContentCell from "../components/faq/FAQContentCell.vue";
const router = useRouter();
const openIndex = ref(-1);
const isMmol = ref(false);
onMounted(function () {
  var query = router.currentRoute.value.query || {}; // undefined
  isMmol.value = query["isMmol"] == 'true';
  console.log(isMmol.value);
});

const items = ref([
    "FAQ_FaqTitle1",
    "FAQ_FaqTitle2",
    "FAQ_FaqTitle3",
    "FAQ_FaqTitle4",
    "FAQ_FaqTitle5",
    "FAQ_FaqTitle6",
    "FAQ_FaqTitle7",
    "FAQ_FaqTitle8",
    "FAQ_FaqTitle9",
    "FAQ_FaqTitle10",
    "FAQ_FaqTitle11",
    "FAQ_FaqTitle12",
    "FAQ_FaqTitle13",
    "FAQ_FaqTitle14"  
]);


const onOpen = (index) => {
  if (openIndex.value == index) {
    openIndex.value = -1;
  } else {
    openIndex.value = index;
  }
};
const showOpen = (index) => {
  return index == openIndex.value;
};
const showNoStr = (index) => {
  var noS = String(index + 1);
  if (index < 9) {
    noS = "0" + noS;
  }
  return noS + ".";
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.flex_c {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-container {
  width: 100%;
  padding: 12px;
  background-color:#ffffff;
  @extend .flex_c;
}

</style>
