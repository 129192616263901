export default {
	"主Key（勿动, 红色自动填充的为重复项，不可有）":"俄罗斯Russian(ru)",
	"Alert_PageOneContent":"Всего вам будет доступно 2 профиля для разных сценариев, например, встречи или других периодов времени в течение дня для оптимального опыта.",
	"Alert_PageTwoContent1":"Нажмите, чтобы изменить имя профиля",
	"Alert_PageTwoContent2":"Нажмите на значки под «Повторить», чтобы установить дни действия этого профиля, нажмите еще раз, чтобы отменить выбор.",
	"Alert_PageThreeContent1":"«Весь день» означает, что ваши настройки будут действовать в течение дня.",
	"Alert_PageThreeContent2":"После отключения оповещения можно изменять по дням и ночам",
	"Alert_PageThreeContent3":"Нажмите на стрелку \">\", чтобы изменить период оповещения для дня и ночи.",
	"Alert_PageFourContent1":"Вы можете отключить экстренное низкое оповещение с помощью переключателей.",
	"Alert_PageFourContent2":"Уровень оповещения нельзя изменить. Приложение уведомит вас, когда показания глюкозы ниже настроек.",
	"Alert_PageFourContent3":"Если продолжается относительно длительный период события экстренного низкого уровня, приложение уведомит вас с установленными интервалами.",
	"Alert_PageFourContent4":"Вы можете настроить звуки оповещения с помощью разных мелодий или вибрации.",
	"Alert_PageFiveContent1":"Вы можете отключить низкое оповещение с помощью переключателя.",
	"Alert_PageFiveContent2":"Уровень оповещения можно изменить; Приложение уведомит вас, когда показания уровня глюкозы ниже установленных значений.",
	"Alert_PageFiveContent3":"Вы можете установить интервал оповещений. Если уровень глюкозы остается в диапазоне низких значений в течение относительно длительного периода, приложение будет уведомлять вас с установленными интервалами.",
	"Alert_PageFiveContent4":"Вы можете настроить звуки оповещений с помощью различных мелодий или вибрации.",
	"Alert_PageSixContent1":"Вы можете отключить оповещение о высоком уровне с помощью переключателя.",
	"Alert_PageSixContent2":"Уровень оповещения можно изменить; приложение уведомит вас, когда показания уровня глюкозы выше установленных значений.",
	"Alert_PageSixContent3":"Вы можете установить интервал оповещений. Если уровень глюкозы остается в диапазоне высоких значений, приложение будет уведомлять вас с установленными интервалами.",
	"Alert_PageSixContent4":"Вы можете вручную отложить первое оповещение о высоком уровне на определенный период. Если уровень глюкозы остается в диапазоне высоких значений, оповещения возобновятся. Например, приложение отправит оповещения через 20 минут после первого события высоких значений.",
	"Alert_PageSixContent5":"Вы также можете настроить звуки оповещений с помощью различных мелодий или вибрации.",
	"Alert_PageSevenContent1":"Нажмите «Добавить профиль оповещений», чтобы создать новую настройку, которая будет действовать в разные периоды. Обратите внимание, что если вы выберете перекрывающиеся дни, которые включены в другой профиль, новые настройки перезапишут существующие для соответствующих дней.",
	"Alert_PageSevenContent2":"Создается новый профиль, вы можете настроить эффективные периоды 2 профилей.",
	"Alert_Setting_Model":"Звуки оповещений",
	"Alert_Setting_DelayFirst":"Задержка первого высокого оповещения",
	"Alert_Setting_Mg_54":"55 мг/дл",
	"Alert_AlertTitle":"Настройте оповещения о глюкозе",
	"Alert_UrgentLowAlert":"Оповещение об опасно низком уровне глюкозы",
	"Alert_LowAlert":"Оповещение о низком уровне глюкозы",
	"Alert_HighAlert":"Оповещение о высоком уровне глюкозы",
	"Monday_Abb":"Пн",
	"Tuesday_Abb":"Вт",
	"Wednesday_Abb":"Ср",
	"Thursday_Abb":"Чт",
	"Friday_Abb":"Пт",
	"Saturday_Abb":"Сб",
	"Sunday_Abb":"Солнце",
	"Alert_Setting_AllDay":"Весь день",
	"Alert_Setting_UrgentLow":"Оповещение об опасно низком уровне глюкозы",
	"Alert_Setting_Low":"Оповещение о низком уровне глюкозы",
	"Alert_Setting_High":"Оповещение о высоком уровне глюкозы",
	"Alert_Setting_Daytime":"Дневное время (08:00-20:00)",
	"Alert_Setting_Nighttime":"Ночное время (20:00-08:00)",
	"Alert_Setting_Interval":"Интервал",
	"Alert_Setting_Level":"Уровень",
	"Alert_Setting_Vibration":"Вибрация",
	"Alert_Setting_Add":"Добавить профиль оповещения",
	"Alert_Setting_System":"Система",
	"Alert_Setting_Mg_180":"180 мг/дл",
	"Alert_Setting_Mg_70":"70 мг/дл",
	"Alert_Setting_Mmol_10":"10,0 ммоль/л",
	"Alert_Setting_Mmol_3":"3,1 ммоль/л",
	"Alert_Setting_Mmol_3_9":"3,9 ммоль/л",
	"Alert_Setting_Default":"По умолчанию",
	"Alert_Setting_Default2":"По умолчанию2",
	"Alert_Setting_Min_5":"5 минут",
	"Alert_Setting_Min_30":"30 минут",
	"Alert_Setting_Repeat":"Повторить",
	"FAQ_FaqTitle1":"Что такое приложение Syai Link?",
	"FAQ_FaqTitle2":"В чем разница между Syai Link и Syai Tag?",
	"FAQ_FaqTitle3":"Должен ли я принимать решения о лечении на основе приложения Syai Link?",
	"FAQ_FaqTitle4":"Как работает приложение Syai Link?",
	"FAQ_FaqTitle5":"Будет ли происходить задержка данных Syai Link? Какова продолжительность задержки?",
	"FAQ_FaqTitle6":"Какие уведомления я буду получать?",
	"FAQ_FaqTitle7":"За сколькими пользователями Syai Tag я могу следить с помощью приложения Syai Link?",
	"FAQ_FaqTitle8":"Когда я устанавливаю связь с несколькими пользователями, чья информация будет отображаться в моем виджете?",
	"FAQ_FaqTitle9":"Могу ли я увидеть исторические данные пациента?",
	"FAQ_FaqTitle10":"Что означают «HI» и «LO»?",
	"FAQ_FaqTitle11":"Что обозначает синяя область на графике уровня глюкозы?",
	"FAQ_FaqTitle12":"Какое отношение целевой диапазон имеет к оповещениям о сахаре в крови?",
	"FAQ_FaqTitle13":"Могу ли я получить доступ к другим приложениям и сервисам с помощью учетной записи Syai Link?",
	"FAQ_FaqTitle14":"Какая информация будет отображаться в центре сообщений?",
	"FAQ_FaqContent1":"Syai Link App - это приложение, специально разработанное для родственников, друзей и других пользователей Syai Tag. Вы можете видеть данные о глюкозе в крови в режиме реального времени, кривую глюкозы в крови и состояние сенсора пользователей Syai Tag, а также получать уведомления в случае аномального уровня глюкозы в крови и других чрезвычайных ситуаций.",
	"FAQ_FaqContent2":"Приложение Syai Link разработано для использования родственниками, друзьями и сиделками. Они могут видеть данные о глюкозе в реальном времени и предупреждения на своих смартфонах. \nПриложение Syai Tag предназначено для людей с диабетом, чтобы проводить регулярный мониторинг глюкозы с помощью смартфона и монитора Syai Tag.",
	"FAQ_FaqContent3":"Данные о глюкозе и аналитика, предоставляемые в приложении, не должны использоваться в качестве прямых рекомендаций по лечению. Прежде чем принимать какие-либо важные решения о лечении, пройдите другие обследования для перепроверки, например, пальцевые тесты, для оптимального лечения.",
	"FAQ_FaqContent4":"Когда приложение Syai Tag App получает данные о глюкозе от сенсора, оно передает их на сервер. Приложение Syai Tag App позволяет пользователю приглашать родственников, друзей и тех, кто за ними ухаживает, следить за состоянием глюкозы пользователя. Эти родственники, друзья и лица, осуществляющие уход, могут установить приложение Syai Link на свой телефон, принять приглашение на совместное использование, а затем просматривать данные о глюкозе и получать предупреждения о глюкозе.",
	"FAQ_FaqContent5":"Задержка данных может быть вызвана различными факторами, такими как задержка в сети, прерывание соединения и так далее. В относительно идеальных условиях задержка не должна превышать нескольких секунд.",
	"FAQ_FaqContent6":"a. Когда показания глюкозы пациента выше/ниже установленных уровней оповещения, таких как высокий, низкий и опасно низкий уровни глюкозы.\nb. Когда уровень глюкозы пациента выходит за пределы установленных диапазонов, то есть ниже %n или выше %r.\nc. Когда участник завершил свою сессию.\nd. Когда аккаунт пациента удаляется.\ne. Когда пользователь прекратил обмен.\nf. Когда есть непрочитанные сообщения.\ng. Когда есть новые приглашения.",
	"FAQ_FaqContent7":"Учетная запись Syai Link может следить за 10 пользователями Syai Tag.\nУчетная запись Syai Tag может пригласить до 10 пользователей Syai Link.",
	"FAQ_FaqContent8":"Из-за ограничений в функциональности виджета мы можем отображать информацию только о первом участнике в вашем списке. Вы можете использовать функцию «Закрепить сверху» на странице настроек, чтобы установить приоритет для предпочитаемого участника.",
	"FAQ_FaqContent9":"Данные в реальном времени и данные за последние 24 часа могут быть просмотрены наблюдателем, но не предыдущие данные. Кроме того, данные можно просмотреть только после принятия приглашения, данные до этой даты просмотреть нельзя.",
	"FAQ_FaqContent10":"«HI» указывает на показания глюкозы выше %r.\n „LO“ указывает на показания глюкозы ниже %n.",
	"FAQ_FaqContent11":"Это целевой диапазон, установленный пациентом в его приложении Syai Tag App, и вы не можете изменить эту настройку.",
	"FAQ_FaqContent12":"Эти два параметра независимы и не влияют друг на друга. Целевой диапазон будет отображаться только на графике уровня глюкозы, установленном пациентом в приложении Syai Tag App, и не может быть изменен в приложении Syai Link App. \nОповещение о глюкозе - это уведомление, отправляемое вам, когда уровень глюкозы у пользователя выходит за пределы нормы. Этот параметр можно изменить на странице \"Настройки\".",
	"FAQ_FaqContent13":"Ваша учетная запись Syai Link фактически является учетной записью Syai, вы можете получить доступ ко всем приложениям и услугам для непрофессионального использования, таким как приложение Syai Tag.",
	"FAQ_FaqContent14":"Все полученные вами уведомления будут отображаться в разделе \"Сообщения\". Вы также можете просмотреть следующие задачи в данном разделе: \na.Ожидаемые приглашения.\nb. Когда появится новое обновление.\nc. Оповещения о высоком/низком/опасно низком уровне глюкозы."
}