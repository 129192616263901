<template>
  <div class="main_container">
    <!-- direction="vertical" -->
    <!-- props的配置跟原来版本实例化时候的配置一样，只是转换格式即可 -->
    <div class="title-large">{{ $t(title) }}</div>
    <div class="main-contrainer2">
      <div class="swiper-container">
        <swiper
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          :slides-per-view="1"
          :space-between="10"
          :modules="modules"
          direction="horizontal"
        >
          <slot></slot>
        </swiper>
      </div>
      <div class="pagination_container">
        <div
          class="pagination"
          :class="{ pagination_selected: index == curIndex }"
          v-for="(item, index) in list"
          :key="index"
        ></div>
      </div>
    </div>
    <div class="bottom_space"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  count: {
    type: Number,
    require: true,
    default: 0,
  },
  title: String,
});

import { ref, toRefs } from "vue";
import { Pagination } from "swiper";
import { Swiper } from "swiper/vue";
import "swiper/css";
import "swiper/scss/pagination";

const curIndex = ref(0);
const { count } = toRefs(props);

const list = ref(new Array(count.value).fill(1));

const onSwiper = (swiper) => {
  console.log(swiper);
};
const onSlideChange = (a) => {
  console.log("slide change");
  console.log(a.activeIndex);
  curIndex.value = a.activeIndex;
};
const modules = [Pagination];
</script>

<style lang="scss" scoped>
.main_container {
  height: 100vh;
  max-height: 100vh;
  overflow: none;
  display: flex;
  flex-direction: column;
  // background-color: #f5f7f9;
  // background-color: red;
  background-color:#F8F9FD;
  padding: 12px;
  .main-contrainer2 {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: scroll;
    border-radius: 10px;
    margin: 12px 0;
    background-color: white;
    .swiper-container {
      display: flex;
      flex: 1;
      // background-color: white;
      background-color:#F8F9FD;
      flex-direction: row;
      overflow: scroll;
      //   .swiper-item {
      //     display: flex;
      //     flex: 1;
      //     background-color: red;
      //   }
      //   .swiper-slide {
      //     padding: 0 12px;
      //     .haha {
      //       height: 200px;
      //       background-color: red;
      //       margin: 10px 0;
      //     }
      //   }
    }
    .pagination_container {
      width: 100%;
      height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color:#F8F9FD;
      .pagination {
        width: 6px;
        height: 6px;
        background-color: #dadcde;
        margin: 4px;
        border-radius: 3px;
      }
      .pagination_selected {
        background-color: #7470e9;
      }
    }
  }
}
.bottom_space {
  width: 100%;
  height: 10px;
}
</style>
