export default {
	"主Key（勿动, 红色自动填充的为重复项，不可有）":"中文（zh)",
	"Alert_PageOneContent":"您可以设置两套血糖提醒的情景方案来应对不同使用场景，如工作或一天中的其他时间段，以获得最优体验。",
	"Alert_PageTwoContent1":"点按编辑提醒方案的名称",
	"Alert_PageTwoContent2":"轻点“重复”下方的圆形图标来设置此提醒方案的触发时间，再次轻点圆形图标来取消已选择的周内日期。",
	"Alert_PageThreeContent1":"“全天”代表您的提醒方案在起效日内将处于全天激活状态",
	"Alert_PageThreeContent2":"关闭此选项后，血糖提醒可按照白天与夜间两个时间段来分别修改起效时间",
	"Alert_PageThreeContent3":"轻点“>”箭头来更改白天或夜间的血糖提醒时间段。",
	"Alert_PageFourContent1":"您可以点击来关闭“紧急低血糖提醒”。",
	"Alert_PageFourContent2":"提醒阈值无法修改，App将在血糖读数低于设定值时提醒您。",
	"Alert_PageFourContent3":"当紧急低血糖事件持续时，App 将按照设定的重复提醒间隔通知您。  ",
	"Alert_PageFourContent4":"您可以为提醒设定不同铃声或振动。 ",
	"Alert_PageFiveContent1":"您可以点击来关闭“低血糖提醒”。",
	"Alert_PageFiveContent2":"提醒阈值可以手动修改，App将在血糖读数低于设定值时提醒您。",
	"Alert_PageFiveContent3":"您可以设置提醒的间隔时间。当低血糖事件持续时，App将按照设定的重复提醒间隔通知您。",
	"Alert_PageFiveContent4":"您可以为提醒设定不同铃声或振动。 ",
	"Alert_PageSixContent1":"您可以点击来关闭“高血糖提醒”。",
	"Alert_PageSixContent2":"您可以手动修改提醒阈值，App将在血糖读数高于设定值时提醒您。",
	"Alert_PageSixContent3":"您可以设置提醒的间隔时间。当高血糖事件持续时，App将按照设定的重复提醒间隔通知您。",
	"Alert_PageSixContent4":"您可以手动将首次“高血糖提醒”延迟一段时间。如果您的血糖水平在延迟一段时间后仍在高警报范围内，高血糖提醒将继续通知您。例如，App将在第一次高血糖事件发生 20 分钟后发出提醒，当延迟时间设定为20分钟时。",
	"Alert_PageSixContent5":"您可以为提醒设定不同铃声或振动。 ",
	"Alert_PageSevenContent1":"点击“添加提醒预案”创建在不同时段生效的新提醒方案。但请注意，如果您选择的生效日期与另一个预案中的对应日期重叠，新方案将覆盖相关日期的现存方案。",
	"Alert_PageSevenContent2":"新提醒方案已保存，您可以自定义其生效周期和具体时间段",
	"Alert_Setting_Model":"提醒方式",
	"Alert_Setting_DelayFirst":"首次高血糖延迟提醒",
	"Alert_Setting_Mg_54":"55 mg/dL",
	"Alert_AlertTitle":"自定义您的血糖提醒",
	"Alert_UrgentLowAlert":"紧急低血糖提醒：",
	"Alert_LowAlert":"低血糖提醒：",
	"Alert_HighAlert":"高血糖提醒：",
	"Monday_Abb":"一",
	"Tuesday_Abb":"二",
	"Wednesday_Abb":"三",
	"Thursday_Abb":"四",
	"Friday_Abb":"五",
	"Saturday_Abb":"六",
	"Sunday_Abb":"日",
	"Alert_Setting_AllDay":"全天生效",
	"Alert_Setting_UrgentLow":"紧急低血糖提醒",
	"Alert_Setting_Low":"低血糖提醒",
	"Alert_Setting_High":"高血糖提醒",
	"Alert_Setting_Daytime":"白天(08:00-20:00)",
	"Alert_Setting_Nighttime":"夜晚(20:00-08:00)",
	"Alert_Setting_Interval":"间隔时间",
	"Alert_Setting_Level":"提醒值",
	"Alert_Setting_Vibration":"振动",
	"Alert_Setting_Add":"添加提醒设置",
	"Alert_Setting_System":"系统铃声",
	"Alert_Setting_Mg_180":"180 mg/dL",
	"Alert_Setting_Mg_70":"70 mg/dL",
	"Alert_Setting_Mmol_10":"10.0 mmol/L",
	"Alert_Setting_Mmol_3":"3.1 mmol/L",
	"Alert_Setting_Mmol_3_9":"3.9 mmol/L",
	"Alert_Setting_Default":"默认提醒",
	"Alert_Setting_Default2":"默认提醒2",
	"Alert_Setting_Min_5":"5分钟",
	"Alert_Setting_Min_30":"30分钟",
	"Alert_Setting_Repeat":"重复设置",
	"FAQ_FaqTitle1":"什么是Syai Link App？",
	"FAQ_FaqTitle2":"Syai Link 和Syai Tag有什么区别？",
	"FAQ_FaqTitle3":"Syai Link App中的血糖数据与分析能作为医学诊断的直接依据吗？",
	"FAQ_FaqTitle4":"Syai Link App的使用场景？",
	"FAQ_FaqTitle5":"Syai Link 的数据会有延迟吗？延迟多久？",
	"FAQ_FaqTitle6":"我会收到哪些通知？",
	"FAQ_FaqTitle7":"邀请和关注数量有限制吗?",
	"FAQ_FaqTitle8":"当我与多个分享者建立连接，我的桌面组件会显示谁的信息？",
	"FAQ_FaqTitle9":"我可以看到分享者的历史数据吗？",
	"FAQ_FaqTitle10":"“HI” 和“LO”分别代表什么？",
	"FAQ_FaqTitle11":"曲线内的蓝色色块代表什么？",
	"FAQ_FaqTitle12":"目标范围和血糖提醒有什么关系？",
	"FAQ_FaqTitle13":"我能否使用 Syai Link 帐户访问其他应用程序或服务？",
	"FAQ_FaqTitle14":"什么信息会被展示在消息中心里？",
	"FAQ_FaqContent1":"Syai Link App是一个专门设计给Syai Tag用户的亲友及家属使用的App,可跟踪Syai Tag使用者的实时血糖数据、血糖曲线和监测仪状态等, 并在血糖异常或低血糖等紧急情况时一并被通知。",
	"FAQ_FaqContent2":"Syai Tag应用程序旨在供糖尿病患者使用,通过智能手机和Syai Tag监测仪联动实现血糖的持续监测。\nSyai Link App则是为Syai Tag 用户的亲友家属等开发，便于他们实时追踪佩戴者的血糖情况。",
	"FAQ_FaqContent3":"本应用程序中提供的血糖数据和相应分析，不应作为直接的医学诊断参考。在对您的健康状况做出任何重要决定之前，请向经过认证的医疗机构或医护人员寻求进一步帮助。",
	"FAQ_FaqContent4":"Syai Tag应用收到来自监测仪的血糖数据时，它将血糖数据传输到服务器，并将数据同步到Syai Link应用内。当Syai Tag用户邀请的家人、朋友在Syai Link应用内接受分享邀请后，就可以在应用内查看血糖数据并接收血糖提醒。",
	"FAQ_FaqContent5":"App中的相关数据延迟会由多种因素引发，例如网络连接问题或信号不良等。在网络良好的情况下，延迟通常在几秒内或更少。",
	"FAQ_FaqContent6":"a. 分享者血糖值超过设置提醒范围时，如高血糖、低血糖、紧急低血糖\nb. 分享者血糖值超过标称范围时，也就是血糖低于%n 或高于%r。\nc. 分享者结束佩戴监测仪时\nd. 分享者账号注销时\ne. 分享者停止分享时\nf. 有其他待处理消息时\ng. 存在新的邀请时",
	"FAQ_FaqContent7":"每个Syai Link用户可以接受最多10个邀请\n每个Syai Tag用户可以发出最多10个邀请",
	"FAQ_FaqContent8":"受桌面组件功能限制，我们只能展示您列表里第一个分享者的信息。您可以通过主页——“亲友详情”页——右上角“设置”——“置顶”功能来置顶分享者。",
	"FAQ_FaqContent9":"您可以看到分享者的实时数据和近24小时的数据，无法看到更早的数据。另外，您只能看到建联后的数据，建联前的数据无法看到。",
	"FAQ_FaqContent10":"HI指血糖读数高于%r。\n\"LO\"\"指血糖读数低于%n。\n当遇到这样的情况时，请提醒分享者测量指尖血进行确认，如果仍出现异常，请帮助分享者就医诊治",
	"FAQ_FaqContent11":"蓝色色块代表分享者在Syai Tag App里设置的目标范围，Syai Link App里无法修改。",
	"FAQ_FaqContent12":"两者是独立的，互不影响。目标范围只会展示在图表内，由分享者在Syai Tag App里设置，Syai Link App里无法修改；血糖提醒是当亲友的血糖超过正常范围后给您发送的通知，您可以在Syai Link App的主页——“亲友详情”页——右上角“设置”——“血糖提醒”里进行修改。",
	"FAQ_FaqContent13":"您的 Syai Link 账户实际上也是 Syai 账户，您可以访问所有非专业用途或院方端的Syai应用程序和服务，如 Syai Tag 应用等。",
	"FAQ_FaqContent14":"所有您收到的通知都会展示在消息中心里。您还可以在消息中心里处理以下待办消息：\na. 未处理的邀请信息\nb. 存在新版本升级\nc. 高/低/紧急低血糖事件的相应提醒。"
}