export default {
	"主Key（勿动, 红色自动填充的为重复项，不可有）":"英语(en)",
	"Alert_PageOneContent":"You will have 2 profiles available in total for different scenarios, such as a meeting or other time periods of the day for optimal experiences.",
	"Alert_PageTwoContent1":"Tap to edit profile name",
	"Alert_PageTwoContent2":"Tap on icons below “Repeat” to set the effective days of this profile, tap again to de-select.",
	"Alert_PageThreeContent1":"“All Day” means your settings will be effective throughout a day.",
	"Alert_PageThreeContent2":"Once turned off, Alerts can be modified by daytime and nighttime",
	"Alert_PageThreeContent3":"Tap on the \">\" arrow to change the alert period for the daytime and nighttime.",
	"Alert_PageFourContent1":"You can switch off the Urgent Low Alert with the toggles.",
	"Alert_PageFourContent2":"The Alert level cannot be modified. The App will notify you when the glucose readings are lower than the settings.",
	"Alert_PageFourContent3":"When there's a relatively long period of an Urgent Low event ongoing, the App will notify you following the set intervals.",
	"Alert_PageFourContent4":"You could customize the Alert Sounds with different ringtones or vibration.",
	"Alert_PageFiveContent1":"You can switch off the Low Alert with the toggle.",
	"Alert_PageFiveContent2":"The Alert level can be changed; the App will notify you when the glucose readings are lower than the settings.",
	"Alert_PageFiveContent3":"You could set the interval of Alerts. When there's a relatively long period of a low event ongoing, the App will notify you at the set intervals.",
	"Alert_PageFiveContent4":"You could customize the Alert Sounds with different ringtones or vibration.",
	"Alert_PageSixContent1":"You can switch off the High Alert with the toggle.",
	"Alert_PageSixContent2":"The Alert level can be changed; the App will notify you when the glucose readings are higher than the settings.",
	"Alert_PageSixContent3":"You could set the interval for Alerts. When there's a relatively long period of a high event ongoing, the app will notify you at the set intervals.",
	"Alert_PageSixContent4":"You can manually delay your first High Alert for a certain period. If your glucose level remains in the high alert range; alert notifications will resume. For example, the app will set off alerts 20 minutes after the first high event.",
	"Alert_PageSixContent5":"You could customize the Alert Sounds with different ringtones or vibration also.",
	"Alert_PageSevenContent1":"Tap \"Add Alerts profile\" to create a new setting that will take effect on different periods. Note that if you select overlapping days that is included in another profile, the new settings will overwrite the existing one for the relevant days.",
	"Alert_PageSevenContent2":"A new profile is created, you can customize the effective periods of the 2 profiles.",
	"Alert_Setting_Model":"Alert Sounds",
	"Alert_Setting_DelayFirst":"Delay First High Alert",
	"Alert_Setting_Mg_54":"55 mg/dL",
	"Alert_AlertTitle":"Customise your glucose alerts",
	"Alert_UrgentLowAlert":"Urgent Low Alert",
	"Alert_LowAlert":"Low Alert",
	"Alert_HighAlert":"High Alert",
	"Monday_Abb":"Mon",
	"Tuesday_Abb":"Tue",
	"Wednesday_Abb":"Wed",
	"Thursday_Abb":"Thu",
	"Friday_Abb":"Fri",
	"Saturday_Abb":"Sat",
	"Sunday_Abb":"Sun",
	"Alert_Setting_AllDay":"All day",
	"Alert_Setting_UrgentLow":"Urgent Low Alert",
	"Alert_Setting_Low":"Low Alert",
	"Alert_Setting_High":"High Alert",
	"Alert_Setting_Daytime":"Daytime(08:00-20:00)",
	"Alert_Setting_Nighttime":"Nighttime(20:00-08:00)",
	"Alert_Setting_Interval":"Interval",
	"Alert_Setting_Level":"Level",
	"Alert_Setting_Vibration":"Vibration",
	"Alert_Setting_Add":"Add Alerts Profile",
	"Alert_Setting_System":"System",
	"Alert_Setting_Mg_180":"180 mg/dL",
	"Alert_Setting_Mg_70":"70 mg/dL",
	"Alert_Setting_Mmol_10":"10.0 mmol/L",
	"Alert_Setting_Mmol_3":"3.1 mmol/L",
	"Alert_Setting_Mmol_3_9":"3.9 mmol/L",
	"Alert_Setting_Default":"Default",
	"Alert_Setting_Default2":"Default2",
	"Alert_Setting_Min_5":"5 minutes",
	"Alert_Setting_Min_30":"30 minutes",
	"Alert_Setting_Repeat":"Repeat",
	"FAQ_FaqTitle1":"What is the Syai Link App?",
	"FAQ_FaqTitle2":"What is the difference between Syai Link and Syai Tag?",
	"FAQ_FaqTitle3":"Should I make treatment decisions based on Syai Link App?",
	"FAQ_FaqTitle4":"How does the Syai Link App work?",
	"FAQ_FaqTitle5":"Will Syai Link data be delayed? How long is the delay?",
	"FAQ_FaqTitle6":"What notifications will I receive?",
	"FAQ_FaqTitle7":"How many Syai Tag users can I follow with the Syai Link App?",
	"FAQ_FaqTitle8":"When I establish a connection with multiple sharers, whose information will my widget display?",
	"FAQ_FaqTitle9":"Can I see the sharer's historical data?",
	"FAQ_FaqTitle10":"What does \"HI\" and \"LO\" mean?",
	"FAQ_FaqTitle11":"What do the blue area in trend graph indicate?",
	"FAQ_FaqTitle12":"What does the target range have to do with blood sugar alerts?",
	"FAQ_FaqTitle13":"Can I access other Apps and Services with my Syai Link account?",
	"FAQ_FaqTitle14":"What information will be displayed in the message center?",
	"FAQ_FaqContent1":"Syai Link App is an app specially designed for relatives, friends, and other Syai Tag users. You can see the real-time blood sugar data, blood sugar curve and monitor status of Syai Tag users, and receive notifications in case of abnormal blood sugar and other emergencies.",
	"FAQ_FaqContent2":"The Syai Link App is designed to be used by family, friends, and caregivers. They could see real-time glucose data and alerts on their smartphones. \nThe Syai Tag App is designed to be used by people with diabetes to carry out routine glucose monitoring using a smartphone and a Syai Tag Monitor.",
	"FAQ_FaqContent3":"The glucose data & analytics provided in-app should not be used as direct treatment referrals. Before making any crucial treatment decisions, please take other examinations to double-check, such as fingerstick tests, for optimal treatment delivery.",
	"FAQ_FaqContent4":"When the Syai Tag App receives glucose data from the Monitor, it transmits the glucose data to the server. The Syai Tag App allows the user to invite family, friends, and caregivers to keep track of the sharer's glucose status. These family, friends, and caregivers can install the Syai Link App on their phones, accept the sharing invitation, and then view glucose data and receive glucose alerts.",
	"FAQ_FaqContent5":"The data latency will be caused by various factors, such as network lag, interrupted connections, and so forth. In relatively ideal conditions, the delay should be less than a few seconds.",
	"FAQ_FaqContent6":"a. When the glucose reading of the patient is above/at/below the set alert levels, such as High, Low, and Urgent Low glucose levels.\nb. When the glucose level of the sharer is out of preset ranges, that is, below %n or above %r.\nc. When the sharer ended his/her session.\nd. When the sharer account is deleted.\ne. When the sharer stoped sharing.\nf. When there are unread messages.\ng. When there are new invitations.",
	"FAQ_FaqContent7":"A Syai Link account can follow up to 10 Syai Tag users.\nA Syai Tag account can invite up to 10 Syai Link users.",
	"FAQ_FaqContent8":"Due to limitations in widget functionality, we can only display the information of the first sharer on your list. You can use the \"Pin on top\" function in the settings page to prioritize the preferred sharer.",
	"FAQ_FaqContent9":"Real-time data and data from the past 24 hours can be viewed by the follower, but not the data before. In addition, you can only see the data after accepting the invitation, data prior to this date can not be viewed.",
	"FAQ_FaqContent10":"\"HI\" indicates a glucose reading higher than %r.\n\"LO\" indicates a glucose reading lower than %n.",
	"FAQ_FaqContent11":"This represents the target range set by the sharer in their Syai Tag App, and you cannot modify this setting.",
	"FAQ_FaqContent12":"The two are independent and do not affect each other. The target range will only be displayed in the trend graph, set by the sharer in the Syai Tag App, and cannot be modified in the Syai Link App. \nGlucose alert is a notification sent to you when the sharer's glucose level exceeds the normal range. This can be modified in the Settings page.",
	"FAQ_FaqContent13":"Your Syai Link account is actually a Syai account, you could access all the Apps and Services for non-professional usage such as the Syai Tag App.",
	"FAQ_FaqContent14":"All notifications you receive will be displayed in the message center. You can also check the following to-do messages in the message center:\na. Pending invitations.\nb. When there's a new update available.\nc. Glucose Alerts for High/Low/Urgent Low events."
}