<template>
  <div class="main-header-container"  @click="clickButton">
    <div class="flex_r" :class="[{ selected: isOpen }]">
      <div class="title-medium" :class="[{ selected: isOpen }]">{{ noStr }}</div>
      <div class="title-medium" :class="[{ selected: isOpen }]">{{ $t(title) }}</div>
    </div>
    <img
      :src="
        isOpen
          ? require('@/assets/arrow_up.png')
          : require('@/assets/arrow_down.png')
      "
    />
  </div>
</template>

<script setup>
// import { ref } from "vue";
const emit = defineEmits(["onOpen"]);
defineProps({ title: String, noStr: String, isOpen: Boolean });
const clickButton = () => {
  emit("onOpen");
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
.flex_c {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flex_r {
  display: flex;
  flex-direction: row;
}
.main-header-container {
  @extend .flex_r;
  width: 100%;
  min-height: 52px;
  padding-top: 17px;
  padding-bottom: 16px;
  align-items: start;
  justify-content: space-between;
  color: #2e3033;
  img {
    padding-top: 1px;
    width: 16px;
    height: 16x;
    margin-left: 8px;
  }
}
.selected {
  color: #7470e9;
}
</style>
