import { createRouter, createWebHashHistory } from "vue-router";
import FAQPage from "../page/FAQPage.vue";
import AlertPage from "../page/AlertPage.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: AlertPage,
    },
    {
        path: "/faq",
        name: "faq",
        component: FAQPage,
    },
    {
        path: "/alert",
        name: "alert",
        component: AlertPage,
    },
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
})

export default router;