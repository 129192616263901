<template>
  <div class="main-item">
    <div
      class="item-container"
      v-for="(content, index) in contents"
      :key="index"
    >
      <div v-if="content.type == 'normal'">
        <div :class="{flex_r:true, setting_row:true, add_line:index!=contents.length-1}">
          <div class="left-title title-medium">{{$t(content.leftTitle)}}</div>
          <div class="right-title body-medium" v-if="content.rightTitle.length>0">{{$t(content.rightTitle)}}</div>
          <div class="right-title body-medium" v-if="content.isShowRight">{{ isMmol ? $t(content.rightTitleMmol):$t(content.rightTitleMg)}}</div>
          <img class="right-image" :src="content.rightImage"/>
        </div>
      </div>
      <div class="repeat-container"  v-if="content.type == 'repeat'">
        <div class="left-title title-medium">{{$t(content.leftTitle)}}</div>
        <div class="repeat-list flex_r">
          <div v-for="(content, index) in repeatList"
            :key="index"
          >
            <div class="repeat-item flex_r">
              <div>{{$t(content)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
defineProps({
  contents: Array,
  isMmol:Boolean,
});
const repeatList = ref([
  "Monday_Abb",
  "Tuesday_Abb",
  "Wednesday_Abb",
  "Thursday_Abb",
  "Friday_Abb",
  "Saturday_Abb",
  "Sunday_Abb",
]);
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
.flex_c {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flex_r {
  display: flex;
  flex-direction: row;
}
.main-item{
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #efefef; 
  padding: 0px 12px;
}
.item-container {
  @extend .flex_c;
  margin: 0px;
  .setting_row{
    padding: 16px 0px;
    align-items: center;

    .left-title{
      flex: 1;
      font-size: 17px;
    }

    .right-title{
      font-size: 16px;
    }

    .right-image{
      height: 20px;
    }
  }

  .repeat-container{
    padding:16px  0px;

    .repeat-list{
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
    }

    .repeat-item{
      flex: 1;
      width: 40px;
      height: 40px;
      background-color: #7470E9;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 400;
      font-size: 15px;
      border-radius: 20px;
    }
  }

  .add_line{
    border-bottom: 1px solid #efefef; /* 底部边框 */
  }
}
</style>
