export default {
	"主Key（勿动, 红色自动填充的为重复项，不可有）":"匈牙利Hungry (hu)",
	"Alert_PageOneContent":"Összesen 2 profil áll rendelkezésére a különböző forgatókönyvekhez, például egy találkozóhoz vagy a nap más időszakaihoz az optimális élmény érdekében.",
	"Alert_PageTwoContent1":"Koppintson a profilnév szerkesztéséhez",
	"Alert_PageTwoContent2":"Érintse meg az ikonokat az „Ismétlés” alatt a profil érvényes napjainak beállításához, érintse meg újra a kijelölés megszüntetéséhez.",
	"Alert_PageThreeContent1":"Az „Egész nap” azt jelenti, hogy a beállítások a nap folyamán érvényesek lesznek.",
	"Alert_PageThreeContent2":"Kikapcsolás után a riasztások módosíthatók nappali és éjszakai idő szerint",
	"Alert_PageThreeContent3":"Érintse meg a \">\" nyilat a nappali és éjszakai riasztási időszak módosításához.",
	"Alert_PageFourContent1":"Az Urgent Low Alert a kapcsolókkal kikapcsolható.",
	"Alert_PageFourContent2":"A riasztási szint nem módosítható. Az alkalmazás értesíti Önt, ha a glükóz értékek alacsonyabbak a beállításoknál.",
	"Alert_PageFourContent3":"Ha viszonylag hosszú ideig tart egy Sürgős alacsony esemény, az alkalmazás a beállított időközönként értesíti Önt.",
	"Alert_PageFourContent4":"Testreszabhatja a figyelmeztető hangokat különböző csengőhangokkal vagy rezgéssel.",
	"Alert_PageFiveContent1":"Az alacsony riasztást a kapcsolóval kapcsolhatja ki.",
	"Alert_PageFiveContent2":"A riasztási szint módosítható; az alkalmazás értesíti Önt, ha a glükózérték alacsonyabb a beállításoknál.",
	"Alert_PageFiveContent3":"Beállíthatja a riasztások időközét. Ha viszonylag hosszú ideig van folyamatban alacsony esemény, az alkalmazás a beállított időközönként értesíti Önt.",
	"Alert_PageFiveContent4":"Testreszabhatja a figyelmeztető hangokat különböző csengőhangokkal vagy rezgéssel.",
	"Alert_PageSixContent1":"A magas riasztást a kapcsolóval kapcsolhatja ki.",
	"Alert_PageSixContent2":"A riasztási szint módosítható; az alkalmazás értesíti Önt, ha a glükóz értékek magasabbak a beállításoknál.",
	"Alert_PageSixContent3":"Beállíthatja a riasztások időközét. Ha viszonylag hosszú ideig tart egy magas esemény, az alkalmazás a beállított időközönként értesíti Önt.",
	"Alert_PageSixContent4":"Az első magas riasztást manuálisan elhalaszthatja egy bizonyos ideig. Ha glükózszintje a magas riasztási tartományban marad; a riasztási értesítések folytatódnak. Például az alkalmazás 20 perccel az első magas esemény után figyelmeztetést ad ki.",
	"Alert_PageSixContent5":"Testreszabhatja a figyelmeztető hangokat különböző csengőhangokkal vagy rezgéssel is.",
	"Alert_PageSevenContent1":"Érintse meg a „Figyelmeztetési profil hozzáadása” lehetőséget egy új beállítás létrehozásához, amely különböző időszakokban lép érvénybe. Vegye figyelembe, hogy ha olyan átfedő napokat választ ki, amelyek egy másik profilban szerepelnek, az új beállítások felülírják a meglévőt a megfelelő napokra vonatkozóan.",
	"Alert_PageSevenContent2":"Új profil jön létre, a 2 profil érvényességi idejét személyre szabhatja.",
	"Alert_Setting_Model":"Figyelmeztető hangok",
	"Alert_Setting_DelayFirst":"Az első magas riasztás késleltetése",
	"Alert_Setting_Mg_54":"55 mg/dL",
	"Alert_AlertTitle":"Igazítsa a glükózértesítéseket",
	"Alert_UrgentLowAlert":"Sürgős alacsony riasztás",
	"Alert_LowAlert":"Alacsony riasztás",
	"Alert_HighAlert":"Magas figyelmeztetés",
	"Monday_Abb":"Hétfő",
	"Tuesday_Abb":"Kedd",
	"Wednesday_Abb":"Szerda",
	"Thursday_Abb":"Csütörtök",
	"Friday_Abb":"Péntek",
	"Saturday_Abb":"Szombat",
	"Sunday_Abb":"Vasárnap",
	"Alert_Setting_AllDay":"Egész nap",
	"Alert_Setting_UrgentLow":"Sürgős alacsony figyelmeztetések",
	"Alert_Setting_Low":"Alacsony figyelmeztetések",
	"Alert_Setting_High":"Magas figyelmeztetések",
	"Alert_Setting_Daytime":"Nappal (08:00-20:00)",
	"Alert_Setting_Nighttime":"Éjszaka (20:00-08:00)",
	"Alert_Setting_Interval":"Intervallum",
	"Alert_Setting_Level":"Szint",
	"Alert_Setting_Vibration":"Rezgés",
	"Alert_Setting_Add":"Új profil hozzáadása",
	"Alert_Setting_System":"Rendszer",
	"Alert_Setting_Mg_180":"180 mg/dL",
	"Alert_Setting_Mg_70":"70 mg/dL",
	"Alert_Setting_Mmol_10":"10,0 mmol/L",
	"Alert_Setting_Mmol_3":"3,1 mmol/L",
	"Alert_Setting_Mmol_3_9":"3,9 mmol/L",
	"Alert_Setting_Default":"Alapértelmezett érték",
	"Alert_Setting_Default2":"Alapértelmezett érték2",
	"Alert_Setting_Min_5":"5 perc",
	"Alert_Setting_Min_30":"30 perc",
	"Alert_Setting_Repeat":"Ismétlés",
	"FAQ_FaqTitle1":"Mi az a Syai Link alkalmazás?",
	"FAQ_FaqTitle2":"Mi a különbség a Syai Link és a Syai Tag között?",
	"FAQ_FaqTitle3":"Hozhatok kezelési döntéseket a Syai Link alkalmazás alapján?",
	"FAQ_FaqTitle4":"Hogyan működik a Syai Link alkalmazás?",
	"FAQ_FaqTitle5":"Késleltetett lesz a Syai Link adatai? Milyen hosszú a késleltetés?",
	"FAQ_FaqTitle6":"Milyen értesítéseket kapok?",
	"FAQ_FaqTitle7":"Hány Syai Tag felhasználót követhetek a Syai Link alkalmazással?",
	"FAQ_FaqTitle8":"Amikor több megosztóval létesítek kapcsolatot, mely információk jelennek meg a widgetemben?",
	"FAQ_FaqTitle9":"Meg tudom nézni a megosztó előzményeit?",
	"FAQ_FaqTitle10":"Mit jelent a „HI” és a „LO”?",
	"FAQ_FaqTitle11":"Mit jelent a kék terület a trendgrafikonban?",
	"FAQ_FaqTitle12":"Mi köze van a céltartománynak a vércukorszint-értesítésekhez?",
	"FAQ_FaqTitle13":"Hozzáférhetek más alkalmazásokhoz vagy szolgáltatásokhoz a Syai Link fiókommal?",
	"FAQ_FaqTitle14":"Milyen információk jelennek meg az értesítési központban?",
	"FAQ_FaqContent1":"A Syai Link alkalmazás kifejezetten a rokonok, barátok és más Syai Tag felhasználók számára készült. Valós időben láthatja a vércukor adatokat, a vércukor görbét, és nyomon követheti a Syai Tag felhasználók állapotát, valamint értesítéseket kaphat a rendellenes vércukorszint és egyéb események esetén.",
	"FAQ_FaqContent2":"A Syai Link alkalmazás család, barátok és gondozók számára készült. Okostelefonjukon valós időben láthatják a glükózadatokat és értesítéseket. A Syai Tag alkalmazás cukorbetegek számára készült, hogy okostelefon és Syai Tag monitor segítségével rendszeresen ellenőrizzék a glükózszintet.",
	"FAQ_FaqContent3":"A Syai Tag alkalmazás cukorbetegek számára készült, hogy okostelefon és Syai Tag monitor segítségével rendszeresen ellenőrizzék a glükózszintet.",
	"FAQ_FaqContent4":"Az alkalmazás által szolgáltatott glükózadatok és elemzések nem tekinthetők közvetlen kezelési javaslatnak. Mielőtt bármilyen fontos kezelési döntést hozna, végezzen további vizsgálatokat, például ujjbegyes teszteket, hogy biztosítsa a kezelés optimális alkalmazását.",
	"FAQ_FaqContent5":"Amikor a Syai Tag alkalmazás megkapja a glükóz adatokat a monitortól, elküldi a glükóz adatokat a szerverre. A Syai Tag alkalmazás lehetővé teszi a felhasználó számára, hogy meghívja családtagjait, barátait és gondozóit, hogy kövessék a glükóz szintjét. Ezek a családtagok, barátok és gondozók telepíthetik a Syai Link alkalmazást telefonjukra, elfogadhatják a megosztási meghívást, és ezután megtekinthetik a glükóz adatokat és értesítéseket kapnak.",
	"FAQ_FaqContent6":"Az adatok késleltetése különböző tényezők miatt következhet be, mint például hálózati késések, megszakadt kapcsolatok stb. Viszonylag ideális körülmények között a késleltetésnek néhány másodpercnél rövidebbnek kell lennie.",
	"FAQ_FaqContent7":"A. Amikor a megosztó eszközének glükózszintje meghaladja a beállított emlékeztetési tartományt, például magas, alacsony és Sürgős alacsony glükózszint.",
	"FAQ_FaqContent8":"B. Amikor a megosztó eszköz glükózszintje kívül",
	"FAQ_FaqContent9":"A widget korlátozott funkcionalitása miatt csak az első megosztó információit jeleníthetjük meg a listájában. Az előnyben részesített megosztó kijelöléséhez használhatja a „Tetejére rögzítés” funkciót a beállítások oldalon.",
	"FAQ_FaqContent10":"A követő valós időben megtekintheti az adatokat és az elmúlt 24 óra adatait, de korábbi adatokat nem. Az adatokat csak a meghívás elfogadása után tekintheti meg, a meghívás előtti adatokat nem lehet megtekinteni.",
	"FAQ_FaqContent11":"A \"HI\" azt jelenti, hogy a glükózszint magasabb, mint %r. A \"LO\" azt jelenti, hogy a glükózszint alacsonyabb, mint %n.",
	"FAQ_FaqContent12":"Ez a kettő független egymástól, és nem befolyásolja egymást. A cél tartomány csak a Syai Tag alkalmazásban beállított trendgrafikonon jelenik meg, és nem módosítható a Syai Link alkalmazásban. A glükózértesítések akkor jelennek meg, ha a felhasználó glükózszintje meghaladja a normál tartományt. Ezt a beállítások oldalon lehet módosítani.",
	"FAQ_FaqContent13":"A Syai Link fiókja valójában egy Syai fiók, amely lehetővé teszi a nem professzionális használatra szánt összes alkalmazás és szolgáltatás, például a Syai Tag alkalmazás elérését.",
	"FAQ_FaqContent14":"Minden értesítés, amelyet kap, megjelenik az üzenetközpontban. Az üzenetközpontban a következo feladatokat is ellenorizheti: a. Feldolgozatlan meghívási információk b. Amikor új verzió frissítés elérheto lesz c. Magas/Alacsony/Sürgos alacsony glükóz események értesítései"
}