<template>
  <div class="main-item-container">
    <HeaderComponent
      :title="title"
      :noStr="noStr"
      @onOpen="onOpen"
      :isOpen="isOpen"
    ></HeaderComponent>
    <div class="item-content-normal" v-show="isOpen">
      <FAQContentQuestion :isMmol="isMmol" :content="list[index]">
      </FAQContentQuestion>
    </div>
  </div>
</template>
  
  <script setup>
import { ref } from "vue";
import HeaderComponent from "../HeaderComponent.vue";
import FAQContentQuestion from "./FAQContentQuestion.vue";
const emit = defineEmits(["onOpen"]);

defineProps({
  title: String,
  noStr: String,
  isOpen: Boolean,
  index: Intl,
  isMmol:Boolean,
});

const list = ref([
  "FAQ_FaqContent1",
  "FAQ_FaqContent2",
  "FAQ_FaqContent3",
  "FAQ_FaqContent4",
  "FAQ_FaqContent5",
  "FAQ_FaqContent6",
  "FAQ_FaqContent7",
  "FAQ_FaqContent8",
  "FAQ_FaqContent9",
  "FAQ_FaqContent10",
  "FAQ_FaqContent11",
  "FAQ_FaqContent12",
  "FAQ_FaqContent13",
  "FAQ_FaqContent14"
]);

const onOpen = () => {
  emit("onOpen");
};
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  
  <style scoped lang="scss">
.flex_c {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flex_r {
  display: flex;
  flex-direction: row;
}

.main-item-container {
  min-height: 52px;
  background-color: #f8f9fd;
  border-radius: 12px;
  padding: 0 12px;
  margin-bottom: 8px;

  .item-content-normal {
    padding-bottom: 12px;
    img {
      padding-top: 12px;
      max-width: 100%;
      height: auto;
    }
    .line-space {
      height: 1px;
      width: 100%;
      margin-top: 16px;
      margin-bottom: 12px;
      background-color: #00000014;
    }
  }
}
</style>
  